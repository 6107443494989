import * as React from "react"
import { Helmet } from "react-helmet"
import { padStart } from "lodash"
import NotFoundPage from "../pages/404"
import Header from "../components/Header"
import * as config from "../constants"
import { graphql } from "gatsby"
import { TemplateWrapper } from "../layouts"

export const Content = ({ content, className }) => <div className={className}>{content}</div>

export const HTMLContent = ({ content, className }) => <div
  className={className}
  dangerouslySetInnerHTML={{ __html: content }}
/>

export const BlogPostTemplate = ({ content, category, description, code, name, SourceUrl, Source }: BlogPostTemplateProps) => {
  const dateTime = (d: Date) => d.getFullYear().toString() + "-" +
    padStart((d.getMonth() + 1).toString(), 2, "0") + "-" +
    padStart(d.getDate().toString(), 2, "0")

  const googleStructuredData: ArticleStructuredData = {
    "@context": "http://schema.org",
    "@type": "Article",
    "name": code,
    "headline": code,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": config.DOMAIN,
    },
    "author": {
      "@type": "Person",
      "name": config.AUTHOR,
    },
    "datePublished": dateTime(new Date("2019-10-18")),
    "dateModified": dateTime(new Date()),
    "description": description,
    "publisher": {
      "@type": "Person",
      "name": config.AUTHOR,
      "logo": {
        "@type": "ImageObject",
        "url": `${config.DOMAIN}/img/publisher-logo.gif`,
      },
    },
  }

  return (
    <>
      <Header />
      <div id="article">
        <Helmet
          title={`What is a ${code} ${name} HTTP Resonse Code?`}
          meta={[
            { name: "description", content: description },
            { property: "og:type", content: "website" },
            { property: "og:title", content: `What is a ${code} HTTP Resonse Code?` },
            { property: "og:description", content: description },
            //   { property: "og:url", content: config.DOMAIN },
            //   { property: "og:image", content: coverImage },
            //   { name: "twitter:card", content: "summary_large_image" },
            //   { name: "twitter:title", content: code },
            //   { name: "twitter:description", content: description },
            //   { name: "twitter:url", content: config.DOMAIN },
            //   { name: "twitter:image", content: coverImage },
          ]}
        />
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(googleStructuredData)}</script>
        </Helmet>
        <header className="post-header post">
          <h1 className="post-title">{code} {name} - {category} Status Code</h1>
        </header>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <p className="text-sm italic">Source: <a href={SourceUrl}>{Source}</a></p>
      </div>
    </>
  )
}

const Post = ({ data }) => {
  if (typeof data === "undefined") {
    return NotFoundPage
  }
  const { markdownRemark: post } = data
  const tags = post.frontmatter.tags ? post.frontmatter.tags : []
  return (
    <TemplateWrapper>
      <div className="article-container">
        <BlogPostTemplate
          code={post.frontmatter.code}
          name={post.frontmatter.name}
          category={post.frontmatter.category}
          description={post.frontmatter.description}
          Source={post.frontmatter.Source}
          SourceUrl={post.frontmatter.SourceUrl}
          content={post.html}
        />
      </div>
    </TemplateWrapper>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostByPath($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        code
        name
        description
        category
        Source
        SourceUrl
      }
    }
  }
`
export interface BlogPostTemplateProps {
  code: string
  name: string
  category: any
  Source: string
  SourceUrl: string
  description: string
  content: any
}

export interface ArticleStructuredData {
  "@context": string
  "@type": string
  "mainEntityOfPage": {
    "@type": string
    "@id": string
  }
  "name": string
  "headline": string
  "author": {
    "@type": string
    "name": string
  },
  "datePublished": string
  "dateModified": string
  description: string
  image?: string
  "publisher": {
    "@type": string
    "name": string
    "logo": {
      "@type": string
      "url": string
    }
  }
}
