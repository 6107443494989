import * as React from "react"
import { Link } from "gatsby"
import * as Constants from "../../constants"

export const Header: React.SFC = () => (
  <header>
    <nav className="lg:max-w-lg xl:max-w-4xl m-auto p-2 lg:p-6 mb-8">
      <span className="p-2 bg-purple-600 text-xs text-white arrow_box"><Link className="text-white" to="/" tabIndex={0} >All {Constants.SITE_TITLE}</Link></span>
    </nav>
  </header>
)

Header.displayName = "Header"

export default Header
